@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Amazon-Ember-Medium";
	src: url("../public/fonts/Amazon-Ember-Medium.ttf");
}

@font-face {
	font-family: "AmazonEmber_Lt";
	src: url("../public/fonts/AmazonEmber_Lt.ttf");
}

@font-face {
	font-family: "AmazonEmber_Rg";
	src: url("../public/fonts/AmazonEmber_Rg.ttf");
}

.btn {
	@apply btn-outline box-border rounded-none font-regular text-normal text-primary
};

.btn-navbar {
	@apply btn m-2 border-0 pl-4 pr-4
};